import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/DefaultNewsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`5K Walk`}</h1>
    <p><strong parentName="p"><em parentName="strong"><em parentName="em">{`The Charity and friends walked to lose Christmas Calories and Pile on Pounds for Shanti Aid`}</em></em></strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eea32b7d3d5c4bc5937e37ae5e65ffe3/218d1/redFeet.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAZVWMKwkHn//xAAbEAACAgMBAAAAAAAAAAAAAAABAgADERITMv/aAAgBAQABBQJsdWGrC0weWuAUXlZ//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERAv/aAAgBAwEBPwFRImT/xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQIBAT8BrX//xAAbEAABBAMAAAAAAAAAAAAAAAAAAQIRMhASQf/aAAgBAQAGPwJHNTFDXhRCIP/EABsQAAICAwEAAAAAAAAAAAAAAAERACExQVFh/9oACAEBAAE/IbyJ3EeQRuI23oMQU9qK13XBiCtT/9oADAMBAAIAAwAAABCYH//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxDUEl//xAAVEQEBAAAAAAAAAAAAAAAAAAARAP/aAAgBAgEBPxBMz//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExUUGh/9oACAEBAAE/EFe0FvrOJZM7bs3K/wCNMdERtitL17EF5Gdi+uomXuoVwcn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "5k walk",
            "title": "5k walk",
            "src": "/static/eea32b7d3d5c4bc5937e37ae5e65ffe3/4b190/redFeet.jpg",
            "srcSet": ["/static/eea32b7d3d5c4bc5937e37ae5e65ffe3/e07e9/redFeet.jpg 200w", "/static/eea32b7d3d5c4bc5937e37ae5e65ffe3/066f9/redFeet.jpg 400w", "/static/eea32b7d3d5c4bc5937e37ae5e65ffe3/4b190/redFeet.jpg 800w", "/static/eea32b7d3d5c4bc5937e37ae5e65ffe3/e5166/redFeet.jpg 1200w", "/static/eea32b7d3d5c4bc5937e37ae5e65ffe3/218d1/redFeet.jpg 1501w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`We raised over £2000`}</h3>
    <p>{`Our route was round Birmingham City Centre`}</p>
    <p>{`We met at House of Fraser Birmingham`}</p>
    <p><em parentName="p">{`Others did 5K walks at the same time and some walked 10K or more !`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2bc21d21e908246f3ebea70d6ff49374/b3608/Geo-Tracker-2021.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEgklEQVQ4y0WUaW9bVRCG/adQUShJvd977Ws7tpPSJK2qwkfEIkD8AxAViN9UIUTZWmji2NdLaqeO7XuWuzkbCPSgcxqVD6Mzo6MZzcz7vpOTUvJcPudVuESrNWmUkOg1mZqTignnyRWDXp+DgwMajQbtdptWq8Xdu3dxHIeNjQ0KhQKu61KtVslFOmOuF2ipyaKURCekOiPVikz0uciuCY4DOu0Ou7u73L9/n3q9Tq1Wo1Qq0Ww2rRnfWO5cT1mr0BbL4rV9Yx2TRBmZDLhahwT9MdVKxSbudHdsJ7dv32Zzc5OdnR1rnufZOBerFbGYIs6GyJVA64hwJYhERCxDrpMRg6Me29vtN8l7e3u2m3w+T6fTsbEZ2cS5UCi0SlChJhUjIr1ERRkrvUTLmMt0wXHvR+r1Jru7Ozx8+NCOfufOHba2tqy/f3BgOzRxTiiBEpJExYjljGR1TCJPmMkxS7HiMlkw6P2K6/l4nku308FzXZtsitZrNQuS2amJc6/knFilKBmxDE+JxR9c6AGRMCsYcp0EjI+e4DeaFJ0qxXKZiudRrddx/DqlSsWOWigWXxf8SyxZC8FKabScE4rnXCQxaXRBJCMu12v6vWNqvo/X6dDau4fXauE0fMqug1Ov4zWbVDyXLQPKtZRc6JCVjlByTCgCYrUkUppYp6yTjGH/iGa7SdX3KTlV240ZeWtzi0rNo+y6ttNipWJ2GHH8KkOrFed6SCQTFosQoWJiOeXvdMJ08AyvVrPjup5HY6fLg08/4eDjj6i1mjiuS8VxKJSK5ITMeDFLOJz2SKIzLtM1F+k5iZpxFQ344eUVQTC0QJTLZdqtFp39PT79/ls+evw13Xvv0Wm3qVQqr0ceL9dEeshgPub3acJv04T+PGT26imHL88YjmfMhi+oVB1LjQcPHrD57iYbt27x9ltvWfk9evTIkt3SxnBQqhmpCljIc04XgucnY4LpiGA24d+riOmoh+O4lrxGepUbZE0BQ5f9/f3/C66jiIWeE8uAtfqdtewjw4iT5YXlIP8Ihv2hTTDyM8nmGJhkIzXDQXM43Btu5hI5IVUnpHJmQUnDIXE4IY5jYjHnOlsxHEzsuPlC3o5oUM4XCtYq1ar9M/u1PJycnTKYnzAXgkSfo0RGLGYocUQcHnKVKo57E/ztbfKlIoVSiTv5PMVSiWL5tb9pKHSjnNxETjgNzzCaVlKhZUSsMhIdkYQ/cZ30CA5/o9Fu47Za+N0unqGK7+M2fKuW2vY21VrNgnVTcMlKxGQ6u7mJKYlSpKsXXCSKw6OfabZb1Lpdap02Zc8lXyxYKzmOJbZRTskcWHMUlmHIJIyZihmnixVSxJyHI+arXxglpzzr/Wn3VCgWaPgNuvcP+Pi7x3z4zVd07r1n72TZIF8okBNnS8KFZKROeSp+4US+JBFzkvAZczFCpTGj/mtiG0DMa9TxwZdf8P7nn+H59Td/9sCGK8Uq1ASh4MkiYKoCLkXAUv/BWJ/wV3bNoDewfPN9n2KxyDsbGzilEk65bH2DsPkzB/Y/aAyUi3SRPysAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "geo tracker",
            "title": "geo tracker",
            "src": "/static/2bc21d21e908246f3ebea70d6ff49374/5a190/Geo-Tracker-2021.png",
            "srcSet": ["/static/2bc21d21e908246f3ebea70d6ff49374/772e8/Geo-Tracker-2021.png 200w", "/static/2bc21d21e908246f3ebea70d6ff49374/e17e5/Geo-Tracker-2021.png 400w", "/static/2bc21d21e908246f3ebea70d6ff49374/5a190/Geo-Tracker-2021.png 800w", "/static/2bc21d21e908246f3ebea70d6ff49374/c1b63/Geo-Tracker-2021.png 1200w", "/static/2bc21d21e908246f3ebea70d6ff49374/29007/Geo-Tracker-2021.png 1600w", "/static/2bc21d21e908246f3ebea70d6ff49374/b3608/Geo-Tracker-2021.png 2160w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      